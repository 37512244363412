import { Controller } from '@hotwired/stimulus'
import colorChannel from '../channels/color_channel'

export default class extends Controller {
  static targets = ['colorInput']

  connect() {
  	this.colorInputTargets.map((target) => { 
    	$(target).colorpicker().on('changeColor', function(e) {
    		var color = e.color.toString('hex')
    		colorChannel.send({type: e.target.name, color: color.replace('#', '')})
		  });

      $(target).colorpicker().on('focusout', function(e) {
        $("[name='iframe_url']").val($("iframe")[0].contentWindow.location.pathname)
        $(`#${e.target.name}_form`)[0].requestSubmit()
      });
    })
  }

  checkDisable() {
    $("[name='iframe_url']").val($("iframe")[0].contentWindow.location.pathname)
    $(`#${event.target.name.replace('_disabled', '')}_form`)[0].requestSubmit()
  }
}