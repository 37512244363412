import { Controller } from "@hotwired/stimulus"
import {basicSetup, EditorView} from "codemirror"
import {EditorState, StateEffect} from "@codemirror/state"

import {html} from "@codemirror/lang-html"
import {css} from "@codemirror/lang-css"
import {javascript} from "@codemirror/lang-javascript"

export default class extends Controller {
  static targets = ["editorHtml", "editorCss", "editorJs"]
  static values = {
    docHtml: String,
    docCss: String,
    docJs: String
  }

  connect() {
    this.editorHtml = new EditorView({
      doc: this.docHtmlValue,
      extensions: [
        basicSetup,
        html(),
        EditorView.editable.of(false),
        EditorView.theme({
          '&': { height: '100%' },
          '.cm-gutter,.cm-content': { minHeight: '100%' },
          '.cm-scroller': { overflow: 'auto', minHeight: '100%' },
        }),
        EditorState.readOnly.of(true)
      ],
      parent: this.editorHtmlTarget,
    })

    this.editorCss = new EditorView({
      doc: this.docCssValue,
      extensions: [
        basicSetup,
        css(),
        EditorView.editable.of(false),
        EditorView.theme({
          '&': { height: '100%' },
          '.cm-gutter,.cm-content': { minHeight: '100%' },
          '.cm-scroller': { overflow: 'auto', minHeight: '100%' },
        }),
        EditorState.readOnly.of(true)
      ],
      parent: this.editorCssTarget,
    })

    this.editorJs = new EditorView({
      doc: this.docJsValue,
      extensions: [
        basicSetup,
        javascript(),
        EditorView.editable.of(false),
        EditorView.theme({
          '&': { height: '100%' },
          '.cm-gutter,.cm-content': { minHeight: '100%' },
          '.cm-scroller': { overflow: 'auto', minHeight: '100%' },
        }),
        EditorState.readOnly.of(true)
      ],
      parent: this.editorJsTarget
    })

    this.editorCss.dispatch({selection: {anchor: this.docCssValue.length}})
    this.editorCss.dispatch({scrollIntoView: false,
      effects: EditorView.scrollIntoView(this.docCssValue.length),
    })
  }

  disconnect() {
    this.editorHtml.destroy()
    this.editorCss.destroy()
    this.editorJs.destroy()
  }
}