// app/javascript/controllers/registration_settings_form_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'registrationCheckbox',
    'registrationForm',
    'virtualSwitch',
    'autoOpenRegistration',
    'autoOpenDays',
    'autoCloseHours',
    'autoCloseMinutes',
    'autoCloseRegistration',
    'registrationLimit',
    'isPaymentRequired',
    'paymentRequired',
    'customMessageCheckbox',
    'customMessage',
  ];

  connect() {
    this.updateVisibility();
    this.updateRegistrationLimitVisibility();
    this.updateCounter();
    this.handleAutoOpenRegistrationChange();
    this.handleAutoCloseRegistrationChange();
  }

  handleAutoOpenRegistrationChange() {
    const autoOpenDaysField = document.getElementById('auto_open_days_field');
    const autoOpenDaysInput = this.autoOpenDaysTarget;
    const isLaterSelected = document.getElementById('later').checked;
    const isImmediatelySelected = document.getElementById('immediately')
      .checked;

    if (isLaterSelected) {
      autoOpenDaysField.style.display = 'block';
    } else {
      autoOpenDaysField.style.display = 'none';
      if (isImmediatelySelected) {
        autoOpenDaysInput.value = 0;
      }
    }
  }

  handleAutoCloseRegistrationChange() {
    const autoCloseTimeField = document.getElementById('auto_close_time_field');
    const autoCloseHoursInput = this.autoCloseHoursTarget;
    const autoCloseMinutesInput = this.autoCloseMinutesTarget;
    const isBeforeClassStartsSelected = document.getElementById(
      'before_class_starts'
    ).checked;
    const isWhenClassStartsSelected = document.getElementById(
      'when_class_starts'
    ).checked;

    if (isBeforeClassStartsSelected) {
      autoCloseTimeField.style.display = 'block';
    } else {
      autoCloseTimeField.style.display = 'none';
      if (isWhenClassStartsSelected) {
        autoCloseHoursInput.value = 0;
        autoCloseMinutesInput.value = 0;
      }
    }

    const autoCloseHours = parseInt(autoCloseHoursInput.value, 10);
    const autoCloseMinutes = parseInt(autoCloseMinutesInput.value, 10);

    if (autoCloseHours === 0 && autoCloseMinutes === 0) {
      this.autoCloseRegistrationTarget.value = false;
    } else {
      this.autoCloseRegistrationTarget.value = true;
    }
  }

  updateVisibility() {
    const isChecked = this.registrationCheckboxTarget.checked;
    this.registrationFormTarget.style.display = isChecked ? 'block' : 'none';
    this.virtualSwitchTarget.style.display = isChecked ? 'block' : 'none';
    this.paymentRequiredTarget.style.display = this.isPaymentRequiredTarget
      .checked
      ? 'block'
      : 'none';
    this.customMessageTarget.style.display = this.customMessageCheckboxTarget
      .checked
      ? 'block'
      : 'none';
  }

  updateRegistrationLimitVisibility() {
    const isVirtual = this.element.querySelector(
      'input[type="radio"][value="only_virtual"]'
    ).checked;
    if (isVirtual) {
      this.registrationLimitTarget.style.display = 'none';
      this.element.querySelector('.registration_limit').value = 0;
    } else {
      this.registrationLimitTarget.style.display = 'block';
    }
  }

  updateCounter() {
    const penaltyMessageInput = this.element.querySelector('#penalty_message');
    const remaining = 255 - penaltyMessageInput.value.length;
    this.element.querySelector('#char_count').textContent =
      'There are ' + remaining + ' characters remaining.';
  }

  handleInputChange() {
    this.updateVisibility();
    this.updateRegistrationLimitVisibility();
    this.updateCounter();
    this.handleAutoOpenRegistrationChange(); // Ensure auto open registration change is handled on input change as well
    this.handleAutoCloseRegistrationChange(); // Ensure auto close registration change is handled on input change as well
  }
}
