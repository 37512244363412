import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['messages', 'input', 'startNewChatButton'];

  connect() {
    this.threadId = document.querySelector('meta[name="thread-id"]').content;
    this.inputTarget.addEventListener('keydown', event => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        this.send(event);
      }
    });

    if (this.hasStartNewChatButtonTarget) {
      this.startNewChatButtonTarget.style.display = 'none';
    }
  }

  send(event) {
    event.preventDefault();
    const message = this.inputTarget.value;

    if (message.trim() === '') return;

    this.hidePlaceholder();

    // Display the user's message
    const userMessageElement = document.createElement('div');
    userMessageElement.classList.add('chatgpt-message', 'user');
    userMessageElement.textContent = `You: ${message}`;
    this.messagesTarget.appendChild(userMessageElement);
    this.scrollToBottom();

    // Show loading indicator
    this.showLoadingIndicator();

    // Clear the input
    this.inputTarget.value = '';

    // Send the message to the backend
    fetch('/student/assistant/send_message', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute('content'),
      },
      body: JSON.stringify({ thread_id: this.threadId, message: message }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          console.error(`Failed to create run: ${data.error}`);
        } else {
          console.log('Run created successfully:', data.run_id);
          this.displayAssistantMessage(data.message);

          // Show "Start New Chat" button after the message is sent
          this.startNewChatButtonTarget.style.display = 'inline-block';
        }
        this.removeLoadingIndicator();
      })
      .catch(error => {
        console.error('Error:', error);
        this.removeLoadingIndicator();
      });
  }

  hidePlaceholder() {
    const placeholder = document.getElementById('no-messages-placeholder');
    if (placeholder) {
      placeholder.style.display = 'none';
    }
  }

  showLoadingIndicator() {
    this.hidePlaceholder();
    if (!document.getElementById('message-text-loading')) {
      const loadingElement = document.createElement('div');
      loadingElement.classList.add('chatgpt-message', 'assistant');
      loadingElement.id = 'message-text-loading';
      loadingElement.innerHTML = `
        <div class="loading-placeholder" style="margin-bottom: 3px;"></div>
      `;
      this.messagesTarget.appendChild(loadingElement);
      this.scrollToBottom();
    }
  }

  removeLoadingIndicator() {
    const loadingElement = document.getElementById('message-text-loading');
    if (loadingElement) {
      this.messagesTarget.removeChild(loadingElement);
    }
  }

  displayAssistantMessage(message) {
    const uniqueId = `assistant-message-${Date.now()}`;
    const assistantMessageElement = document.createElement('div');
    assistantMessageElement.classList.add('chatgpt-message', 'assistant');
    assistantMessageElement.innerHTML = `Assistant: <span id="${uniqueId}"></span>`;
    this.messagesTarget.appendChild(assistantMessageElement);

    const assistantMessageSpan = document.getElementById(uniqueId);
    let i = 0;
    const speed = 5;
    const typeWriter = () => {
      if (i < message.length) {
        if (message.charAt(i) === '\n') {
          assistantMessageSpan.innerHTML += '<br>';
        } else {
          assistantMessageSpan.innerHTML += message.charAt(i);
        }
        i++;
        setTimeout(typeWriter, speed);
      } else {
        assistantMessageSpan.innerHTML = message.replace(/\n/g, '<br>');
      }
      this.scrollToBottom();
    };
    typeWriter();
  }

  scrollToBottom() {
    this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight;
  }
}
