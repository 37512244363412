import { Controller } from '@hotwired/stimulus'
import colorChannel from '../channels/color_channel'

export default class extends Controller {
  static targets = ['input', 'value']

  connect() {
  }

  sendTransparency() {
    $(this.valueTarget).html(parseInt($(this.inputTarget).val() * 100) + '%');
    colorChannel.send({type: event.target.name, opacity: $(this.inputTarget).val(), color: ''})
  }

  submitForm() {
    $(this.valueTarget).html(parseInt($(this.inputTarget).val() * 100) + '%');
    colorChannel.send({type: event.target.name, opacity: $(this.inputTarget).val(), color: ''})
    $("[name='iframe_url']").val($("iframe")[0].contentWindow.location.pathname)
    $(`#${event.target.name}_form`)[0].requestSubmit()
  }
}