import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static values = {
	  hasHiddenClass: Boolean,
	  hiddenClass: String,
	  class: String
	}

  static targets = ['toggleOff', 'toggleOn']
  static classes = ['hidden']

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden'
    $('input[type=checkbox][data-toggle^=toggle]').bootstrapToggle()
  }

  toggle () {
    this.toggleOffTargets.map((target) => { target.classList.toggle(this.class) })
    this.toggleOnTargets.map((target) => { target.classList.toggle(this.class) })
  }

  checkbox (event) {
    if (event.target.checked) {
      this.toggleOffTarget.classList.add(this.class)
      this.toggleOnTarget.classList.remove(this.class)
    } else {
      this.toggleOffTarget.classList.remove(this.class)
      this.toggleOnTarget.classList.add(this.class)
    }
  }
}