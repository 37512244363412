import { Controller } from '@hotwired/stimulus';
import '../css/utilities';

export default class extends Controller {
  static targets = ['file', 'icon', 'progress', 'preview'];

  connect() {
    if (this.hasFileTarget) {
      this.fileTarget.style.opacity = 0;
      this.fileTarget.style.width = 0;
      this.fileTarget.style.height = 0;
    }
  }

  setPreview(evt) {
    var file = evt.target.files[0];
    if ((file.size / 1024 / 1024).toFixed(2) > 10) {
      alert(
        'Your file is more than 1 MB in size. Please upload a file less than 1 MB.'
      );
      $(evt.target).val('');
      return;
    }
    var reader = new FileReader();
    var preview = this.previewTarget;

    reader.onloadend = function() {
      preview.src = reader.result;
      preview.style.display = 'block'; // Display the preview image
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      preview.src = '';
      preview.style.display = 'none'; // Hide the preview image if no file selected
    }
  }

  submit(evt) {
    const file = this.fileTarget.files[0];

    const FILE_TYPES = [
      'audio/mpeg',
      'audio/mp3', // To satisfy Chrome.
      'audio/wave',
      'audio/wav',
      'audio/x-wav',
      'audio/x-pn-wav',
      'audio/vnd.wav',
      'audio/ogg',
      'audio/flac',
      'audio/x-flac',
      'audio/x-caf',
      'audio/webm',
      'audio/m4a',
      'audio/x-m4a',
      'audio/mp4',
      'video/mp4',
      'image/png',
      'image/jpg',
      'image/jpeg',
    ];

    if (!FILE_TYPES.includes(file.type)) {
      alert(
        `You're trying to upload a file named ${file.name}. Please upload one of these types: .mp3, .wav, .mpy or m4a`
      );
      return;
    }

    const THREE_GIGABYTES = 3221225472;
    if (file.size > THREE_GIGABYTES) {
      alert(
        'Your audio file is more than 3 GB in size. Please upload a file less than 3 GB.'
      );
      return;
    }

    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', e => {
      if (e.lengthComputable) {
        let percentage = Math.round((e.loaded * 100) / e.total);

        if (percentage > 99) {
          percentage = 99;
        }

        this.progressTarget.style.width = `${percentage}%`;

        if (this.hasIconTarget) {
          this.iconTarget.textContent = `${percentage}%`;
        }
      }
    });

    xhr.addEventListener('load', e => {
      if (xhr.status >= 200 && xhr.status < 400) {
        if (this.hasIconTarget) {
          this.iconTarget.textContent = '✔';
        }
        location.reload();
      } else {
        switch (xhr.status) {
          case 422:
            const errors = JSON.parse(xhr.response);
            alert(errors['file'][0]);
            break;
          default:
            alert('Sorry, something went wrong. Please try again.');
        }
      }
    });

    xhr.open('POST', this.element.action, true);

    const data = new FormData();
    data.append(this.fileTarget.name, file);

    const csrfToken = document.querySelector('meta[name="csrf-token"]');
    xhr.setRequestHeader('X-CSRF-Token', csrfToken && csrfToken.content);

    xhr.send(data);
  }
}
