// app/javascript/controllers/chat_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'chatContainer',
    'availabilityContainer',
    'input',
    'history',
    'availabilityHistory',
    'createServiceEventButton',
    'serviceProviderSelect',
    'bookableResourceSelect',
    'pickADate',
    'startTime',
  ];

  connect() {
    console.log('Chat controller connected');
    this.threadId = null;
    this.serviceDetails = null;
    this.pickADateTarget.addEventListener('change', this.chooseDate.bind(this));
  }

  setThreadId(id) {
    this.threadId = id; // Method to set thread ID after creation
  }

  setServiceDetails(details) {
    this.serviceDetails = details; // Method to set service details
  }

  toggleChat(event) {
    const serviceId = event.target.value;
    if (serviceId) {
      this.chatContainerTarget.style.display = 'block';
      this.availabilityContainerTarget.style.display = 'block';
      this.createThread(serviceId);
    } else {
      this.chatContainerTarget.style.display = 'none';
      this.availabilityContainerTarget.style.display = 'none';
    }
  }

  createThread(serviceId) {
    fetch(`/service_events/create_thread`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ service_id: serviceId }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.thread_id) {
          console.log('Thread created with ID:', data.thread_id);
          this.setThreadId(data.thread_id);
          this.fetchServiceDetails(serviceId); // Fetch service details after creating thread
        } else {
          console.error('Failed to retrieve thread ID:', data);
        }
      })
      .catch(error => console.error('Error creating thread:', error));
  }

  fetchServiceDetails(serviceId) {
    fetch(`/service_details/${serviceId}`)
      .then(response => response.json())
      .then(data => {
        this.setServiceDetails(data.message); // Set the service details
        const instructionsTextarea = document.getElementById(
          'instructionsTextarea'
        );
        instructionsTextarea.value = this.prependCurrentDate(data.message); // Populate the modal with service details and current date
      })
      .catch(error => console.error('Error fetching service details:', error));
  }

  prependCurrentDate(message) {
    const now = new Date();
    const currentDate = now.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const currentTime = now.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
    return `**Today is ${currentDate} and the time is ${currentTime}.**\n\n${message}`;
  }

  send(event) {
    event.preventDefault(); // Prevent the form from submitting
    const message = this.inputTarget.value;
    this.inputTarget.value = ''; // Clear input after sending
    const userMessageElement = document.createElement('div');
    userMessageElement.classList.add('chatgpt-message', 'user');
    userMessageElement.textContent = `You: ${message}`;
    this.historyTarget.appendChild(userMessageElement);

    if (message.trim() !== '' && this.threadId) {
      this.scrollToBottom();

      // Show loading indicator
      this.showLoadingIndicator();
      const instructionsTextarea = document.getElementById(
        'instructionsTextarea'
      );
      const editedInstructions = this.prependCurrentDate(
        instructionsTextarea.value
      );

      if (['development', 'staging'].includes(process.env.RAILS_ENV)) {
        $('#editInstructionsModal').modal('show');

        document.getElementById('saveEditedInstructions').onclick = () => {
          this.createRunWithInstructions(message, editedInstructions);
          $('#editInstructionsModal').modal('hide'); // Close the modal after button click
        };
      } else {
        this.createRunWithInstructions(message, editedInstructions);
      }
    } else {
      console.error('Message is empty or thread ID is not set.');
      this.displayAssistantMessage(
        'Failed to send message. Thread ID is not set.'
      );
      this.addToAvailabilityHistory(
        'Failed to send message. Thread ID is not set.'
      );
    }
  }

  createRunWithInstructions(message, instructions = '') {
    const additionalMessages = [
      {
        role: 'user',
        content: instructions || this.serviceDetails,
      },
    ];

    fetch('/service_events/availability_chat', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
      },
      body: JSON.stringify({
        message: message,
        thread_id: this.threadId,
        additional_messages: additionalMessages,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.response) {
          // Now fetch the latest messages to update the chat box
          this.fetchThreadMessages();
        } else if (data.error) {
          console.error('Error from AI:', data.error);
          this.displayAssistantMessage(
            'Error processing your request. Please try again.'
          );
          this.addToAvailabilityHistory(
            'Error processing your request. Please try again.'
          );
        }
      })
      .catch(error => {
        console.error('Error:', error);
        this.displayAssistantMessage(
          'Failed to connect. Please check your connection and try again.'
        );
        this.addToAvailabilityHistory(
          'Failed to connect. Please check your connection and try again.'
        );
      });
  }

  displayAssistantMessage(text) {
    const uniqueId = `assistant-message-${Date.now()}`;
    const assistantMessageElement = document.createElement('div');
    assistantMessageElement.classList.add('chatgpt-message', 'assistant');
    assistantMessageElement.innerHTML = `Availability Assistant: <span id="${uniqueId}"></span>`;
    this.historyTarget.appendChild(assistantMessageElement);

    const assistantMessageSpan = document.getElementById(uniqueId);
    let i = 0;
    const speed = 5;
    const typeWriter = () => {
      if (i < text.length) {
        if (text.charAt(i) === '\n') {
          assistantMessageSpan.innerHTML += '<br>';
        } else {
          assistantMessageSpan.innerHTML += text.charAt(i);
        }
        i++;
        setTimeout(typeWriter, speed);
      } else {
        assistantMessageSpan.innerHTML = text.replace(/\n/g, '<br>');
        this.scrollToBottom();
      }
    };
    typeWriter();
    const dateSelected = text.match(/Date:\s*([\s\S]+?)\s*(?:\n|$)/);
    const timeSlotSelected = text.match(
      /Time:\s*(\d{1,2}:\d{2}\s*(?:AM|PM))\s*to\s*(\d{1,2}:\d{2}\s*(?:AM|PM))/
    );
    if (dateSelected) {
      // Attempt to create a Date object
      const parsedDate = new Date(dateSelected[1]);

      // Check if the date is valid
      const dateValid = !isNaN(parsedDate.getTime());

      if (dateValid) {
        // Show and update the button if the date can be parsed
        this.createServiceEventButtonTarget.style.display = 'block';
        this.createServiceEventButtonTarget.textContent =
          'Confirm Booking for ' + dateSelected[1] + ' ' + timeSlotSelected[1];
      } else {
        this.createServiceEventButtonTarget.style.display = 'none';
        console.log('Invalid date format:', dateSelected[1]);
      }
    } else {
      this.createServiceEventButtonTarget.style.display = 'none';
      console.log('No date found in the text');
    }
  }

  addToAvailabilityHistory(htmlText) {
    this.availabilityHistoryTarget.innerHTML = ''; // Clear existing content
    const availabilityMessageElement = document.createElement('div');
    availabilityMessageElement.innerHTML = htmlText;
    this.availabilityHistoryTarget.appendChild(availabilityMessageElement);
  }

  fetchThreadMessages() {
    fetch(`/service_events/fetch_thread_messages?thread_id=${this.threadId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.messages && data.messages.length > 0) {
          const lastMessage = data.messages[0];
          // Define regex to match the HTML snippet
          const htmlSnippetRegex = /```html\n([\s\S]*?)```\n/;

          const displayedMessages = new Set(
            [...this.historyTarget.querySelectorAll('div')].map(div =>
              div.textContent.trim()
            )
          );
          const availabilityDisplayedMessages = new Set(
            [...this.historyTarget.querySelectorAll('div')].map(div =>
              div.textContent.trim()
            )
          );
          const htmlMatch = lastMessage.match(htmlSnippetRegex);
          const htmlSnippet = htmlMatch ? htmlMatch[1] : '';
          const cleanedMessage = lastMessage.replace(htmlSnippetRegex, '');
          if (!displayedMessages.has(lastMessage)) {
            this.displayAssistantMessage(cleanedMessage);
          }
          if (!availabilityDisplayedMessages.has(lastMessage)) {
            this.addToAvailabilityHistory(htmlSnippet);
          }
        } else {
          console.error('Failed to fetch messages:', data.error);
          this.displayAssistantMessage(
            'Failed to retrieve conversation history.'
          );
          this.addToAvailabilityHistory(
            'Failed to retrieve conversation history.'
          );
          this.displayAssistantMessage(
            'Failed to retrieve conversation history.'
          );
        }
        this.removeLoadingIndicator();
      })
      .catch(error => {
        console.error('Error fetching messages:', error);
        this.displayAssistantMessage('Error retrieving messages.');
        this.addToAvailabilityHistory('Error retrieving messages.');
        this.removeLoadingIndicator();
      });
  }

  showLoadingIndicator() {
    if (!document.getElementById('message-text-loading')) {
      const loadingElement = document.createElement('div');
      loadingElement.classList.add('chatgpt-message', 'assistant');
      loadingElement.id = 'message-text-loading';
      loadingElement.innerHTML = `
        <div class="loading-placeholder" style="margin-bottom: 3px;"></div>
      `;
      this.historyTarget.appendChild(loadingElement);
      this.scrollToBottom();
    }
  }

  removeLoadingIndicator() {
    const loadingElement = document.getElementById('message-text-loading');
    if (loadingElement) {
      this.historyTarget.removeChild(loadingElement);
    }
  }

  scrollToBottom() {
    this.historyTarget.scrollTop = this.historyTarget.scrollHeight;
  }

  askNextClassAvailability() {
    this.inputTarget.value = 'What is the next available appointment time?';
    this.send(new Event('click'));
  }

  chooseDate(event) {
    const selectedDate = event.target.value; // This will be in the format 'YYYY-MM-DD'

    //We do the following to avoid having issues with timezones, to make sure the date that is picked by the user is the one we send on the string below
    const [year, month, day] = selectedDate.split('-').map(Number);
    const date = new Date(year, month - 1, day, 12, 0, 0);

    // Format the date to a more natural language format
    const formattedDate = date.toLocaleDateString('en-US', {
      weekday: 'long', // Full name of the day (e.g., 'Tuesday')
      month: 'short', // Short name of the month (e.g., 'Sep')
      day: '2-digit', // Two-digit day (e.g., '03')
      year: 'numeric', // Full numeric year (e.g., '2024')
    });

    this.inputTarget.value = `I would like to book at ${formattedDate}?`;
    this.send(new Event('click'));
  }
}
