import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'modal',
    'drawer',
    'signatureModal',
    'editSignatureModal'
  ];

  connect() {
    console.log('CustomDomainController connected');
    document.addEventListener('click', this.handleDocumentClick.bind(this));
  }

  disconnect() {
    document.removeEventListener('click', this.handleDocumentClick.bind(this));
  }

  handleDocumentClick(event) {
    if (event.target.matches('[data-action="custom-domain#copyText"]')) {
      this.copyText(event);
    }
  }

  showModal() {
    console.log('showModal action triggered');
    $(this.modalTarget).modal('show'); // Use jQuery to show modal
  }

  hideModal() {
    console.log('hideModal action triggered');
    $(this.modalTarget).modal('hide'); // Use jQuery to hide modal
  }

  toggleDrawer(event) {
    console.log('toggleDrawer action triggered');
    const drawer = event.currentTarget.closest('.domain-status').nextElementSibling;
    if (drawer) {
      drawer.classList.toggle('hidden');
      drawer.classList.toggle('visible');
      // Attach event listener for the copyable elements within the drawer
      drawer.querySelectorAll('.copyable').forEach(element => {
        element.addEventListener('click', this.copyText.bind(this));
      });
    } else {
      console.error('Drawer element not found');
    }
  }

  deleteSignature(event) {
    console.log('deleteSignature action triggered');
    const signatureId = event.currentTarget.dataset.signatureId;
    fetch(`/custom_domains/${signatureId}/delete_signature`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
      },
    })
      .then(response => {
        if (response.ok) {
          window.location.reload();
        } else {
          throw new Error('Network response was not ok');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  showSignatureModal(event) {
    const domainId = event.currentTarget.dataset.domainId;
    const domainName = event.currentTarget.dataset.domainName;
    const form = this.signatureModalTarget.querySelector('form');

    form.action = `/custom_domains/${domainId}/add_signature`;
    document.getElementById('domainNamePlaceholder').textContent = domainName;

    const modal = document.getElementById('addSignatureModal');
    if (modal) {
      $(modal).modal('show');
    } else {
      console.error('Modal element not found');
    }
  }

  submitSignatureForm(event) {
    event.preventDefault();
    const form = event.target;
    const url = form.action;

    const fromEmailLocal = form.querySelector('[name="sender_signature[from_email_local]"]').value;
    const domainName = document.getElementById('domainNamePlaceholder').textContent;
    const fromEmail = `${fromEmailLocal}@${domainName}`;
    const name = form.querySelector('[name="sender_signature[name]"]').value;
    const replyToEmail = form.querySelector('[name="sender_signature[reply_to_email]"]').value;

    if (fromEmailLocal) {
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
        },
        body: JSON.stringify({
          sender_signature: {
            from_email: fromEmail,
            name: name,
            reply_to_email: replyToEmail,
          },
        }),
      })
        .then(response => {
          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error('Network response was not ok');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    } else {
      console.error('From email is required');
    }
  }

  showEditSignatureModal(event) {
    console.log('showEditSignatureModal action triggered');
    const signatureId = event.currentTarget.dataset.signatureId;
    const fromEmail = event.currentTarget.dataset.fromEmail;
    const name = event.currentTarget.dataset.name;
    const replyToEmail = event.currentTarget.dataset.replyToEmail;

    const form = this.editSignatureModalTarget.querySelector('form');
    form.action = `/custom_domains/${signatureId}/edit_signature`;
    form.method = 'post';  // Ensure this is set correctly for PATCH method

    form.querySelector('[name="sender_signature[from_email_local]"]').value = fromEmail.split('@')[0];
    form.querySelector('[name="sender_signature[name]"]').value = name;
    form.querySelector('[name="sender_signature[reply_to_email]"]').value = replyToEmail;
    document.getElementById('editDomainNamePlaceholder').textContent = fromEmail.split('@')[1];

    const modal = document.getElementById('editSignatureModal');
    if (modal) {
      $(modal).modal('show');
    } else {
      console.error('Edit Signature Modal element not found');
    }
  }

  submitEditSignatureForm(event) {
    event.preventDefault();
    console.log('submitEditSignatureForm action triggered');
    const form = event.target;
    const url = form.action;

    const fromEmailLocal = form.querySelector('[name="sender_signature[from_email_local]"]').value;
    const domainName = document.getElementById('editDomainNamePlaceholder').textContent;
    const fromEmail = `${fromEmailLocal}@${domainName}`;
    const name = form.querySelector('[name="sender_signature[name]"]').value;
    const replyToEmail = form.querySelector('[name="sender_signature[reply_to_email]"]').value;

    if (fromEmailLocal) {
      fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
        },
        body: JSON.stringify({
          sender_signature: {
            from_email: fromEmail,
            name: name,
            reply_to_email: replyToEmail,
          },
        }),
      })
        .then(response => {
          if (response.ok) {
            return response.json();  // Parse the JSON response
          } else {
            return response.text().then(text => { throw new Error(text) });
          }
        })
        .then(data => {
          console.log(data);  // Log the JSON response
          $(this.editSignatureModalTarget).modal('hide'); // Close the modal
          window.location.reload();
        })
        .catch(error => {
          console.error('Failed to update signature', error);
        });
    } else {
      console.error('From email is required');
    }
  }

  copyText(event) {
    const target = event.currentTarget;
    if (!target || !target.dataset) {
      if (!this.loggedDatasetError) {
        console.error('No dataset available on event currentTarget');
        this.loggedDatasetError = true;
      }
      return;
    }

    const value = target.dataset.value ? target.dataset.value.trim() : '';
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(value);
      return;
    }
    navigator.clipboard.writeText(value).then(() => {
      console.log('Text copied to clipboard:', value);
      this.showFeedback('Copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy text:', err);
    });
  }

  fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      console.log('Fallback: Text copied to clipboard:', text);
      this.showFeedback('Copied to clipboard');
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }

  showFeedback(message) {
    const feedbackElement = document.createElement('div');
    feedbackElement.className = 'feedback-message';
    feedbackElement.innerText = message;
    document.body.appendChild(feedbackElement);

    setTimeout(() => {
      feedbackElement.remove();
    }, 2000);
  }
}
