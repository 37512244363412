import consumer from './consumer';

const channel = consumer.subscriptions.create(
  {
    channel: 'ColorChannel',
    subdomain: window.location.hostname.split('.')[0],
  },
  {
    connected() {},

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      if (window.location.href.includes('/preview')) {
        if (data.type == 'primary_text_color') {
          $('.home-header').css('color', `#${data.color}`);
        }
        if (data.type == 'primary_text_color_internal_pages') {
          $('.subheader h1').attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.fc-header-title h2')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.fc-event-title')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.fc-event-title a')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.fc-event-soldout')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.fc-event-time')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.fc-day-number')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.embedded-header')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.embedded-footer')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.fc-button')
            .attr('style', `color: #${data.color} !important`);

          $('.tulasoftware-ondemand-iframe')
            .contents()
            .find('body')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-ondemand-iframe')
            .contents()
            .find('legend')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-ondemand-iframe')
            .contents()
            .find('.submit-btn')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-ondemand-iframe')
            .contents()
            .find('.submit-btn.outline')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-ondemand-iframe')
            .contents()
            .find('.submit-btn.outline')
            .hover(function() {
              $(this).attr('style', `color: #${data.color} !important`);
            });
          $('.tulasoftware-ondemand-iframe')
            .contents()
            .find('.submit-btn.outline')
            .attr('style', `border-color: #${data.color} !important`);

          $('.tulasoftware-ondemand-chat-iframe')
            .contents()
            .find('body')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-ondemand-chat-iframe')
            .contents()
            .find('legend')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-ondemand-chat-iframe')
            .contents()
            .find('.chat-message__submit')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-ondemand-chat-iframe')
            .contents()
            .find('.chat-message__submit.outline')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-ondemand-chat-iframe')
            .contents()
            .find('.chat-message__submit.outline')
            .hover(function() {
              $(this).attr('style', `color: #${data.color} !important`);
            });
          $('.tulasoftware-ondemand-chat-iframe')
            .contents()
            .find('.chat-message__submit.outline')
            .attr('style', `border-color: #${data.color} !important`);

          $('.tulasoftware-purchase-iframe')
            .contents()
            .find('body')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-purchase-iframe')
            .contents()
            .find('legend')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-purchase-iframe')
            .contents()
            .find('.btn-success')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-purchase-iframe')
            .contents()
            .find('.btn-success.outline')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-purchase-iframe')
            .contents()
            .find('.btn-success.outline')
            .hover(function() {
              $(this).attr('style', `color: #${data.color} !important`);
            });
          $('.tulasoftware-purchase-iframe')
            .contents()
            .find('.btn-success.outline')
            .attr('style', `border-color: #${data.color} !important`);

          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-wrap')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-pass-badge')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.pass-info-top div')
            .attr('style', `color: #${data.color} !important`);
        }

        if (data.type == 'secondary_text_color_internal_pages') {
          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.fc-event-instructor')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.fc-event-instructor a')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.fc-day-header')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.fc-agendaList-day')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.fc-event-instructor a')
            .hover(function() {
              $(this).attr('style', `color: #${data.color} !important`);
            });

          var defaultColor = $('.tulasoftware-ondemand-iframe')
            .contents()
            .find('a.text-muted')
            .css('color');
          $('.tulasoftware-ondemand-iframe')
            .contents()
            .find('.help-block')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-ondemand-iframe')
            .contents()
            .find('.text-muted')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-ondemand-iframe')
            .contents()
            .find('a.text-muted')
            .attr('style', `color: #${defaultColor} !important`);
          $('.tulasoftware-ondemand-iframe')
            .contents()
            .find('.submit-btn')
            .attr('style', `color: #${data.color} !important`);

          var defaultColor = $('.tulasoftware-ondemand-chat-iframe')
            .contents()
            .find('a.text-muted')
            .css('color');
          $('.tulasoftware-ondemand-chat-iframe')
            .contents()
            .find('.help-block')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-ondemand-chat-iframe')
            .contents()
            .find('.text-muted')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-ondemand-chat-iframe')
            .contents()
            .find('a.text-muted')
            .attr('style', `color: #${defaultColor} !important`);
          $('.tulasoftware-ondemand-chat-iframe')
            .contents()
            .find('.chat-message__submit')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-ondemand-chat-iframe')
            .contents()
            .find('.chat-message__submit.outline')
            .hover(function() {
              $(this).attr('style', `color: #${data.color} !important`);
            });

          var defaultColor = $('.tulasoftware-purchase-iframe')
            .contents()
            .find('a.text-muted')
            .css('color');
          $('.tulasoftware-purchase-iframe')
            .contents()
            .find('.help-block')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-purchase-iframe')
            .contents()
            .find('.text-muted')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-purchase-iframe')
            .contents()
            .find('a.text-muted')
            .attr('style', `color: #${defaultColor} !important`);
          $('.tulasoftware-purchase-iframe')
            .contents()
            .find('.btn-success')
            .attr('style', `color: #${data.color} !important`);

          var defaultColor = $('.tulasoftware-student-iframe')
            .contents()
            .find('a.text-muted')
            .css('color');
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.help-block')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.text-muted')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-student-iframe')
            .contents()
            .find('a.text-muted')
            .attr('style', `color: #${defaultColor} !important`);
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.content-wrap h1')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-empty-view')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-info')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.btn-success')
            .attr('style', `color: #${data.color} !important`);
        }

        if (data.type == 'link_color_internal_pages') {
          $('.subheader a').attr('style', `color: #${data.color} !important`);

          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.register-event')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.embedded-header a')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.embedded-footer a')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.register-event')
            .hover(function() {
              $(this).attr('style', `color: #${data.color} !important`);
            });

          $('.tulasoftware-ondemand-iframe')
            .contents()
            .find('a')
            .attr('style', `color: #${data.color} !important`);

          $('.tulasoftware-ondemand-chat-iframe')
            .contents()
            .find('a')
            .attr('style', `color: #${data.color} !important`);

          $('.tulasoftware-purchase-iframe')
            .contents()
            .find('a')
            .attr('style', `color: #${data.color} !important`);

          var defaultColor = $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-nav-pills li.active a')
            .css('color');
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-wrap a')
            .attr('style', `color: #${data.color}`);
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-wrap .btn-link')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.credits')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-pass .price')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-nav-pills li.active a')
            .attr('style', `color: ${defaultColor} !important`);
        }

        if (data.type == 'button_color_internal_pages') {
          $('.tulasoftware-calendar-iframe')
            .contents()
            .find('.fc-button')
            .attr('style', `background-color: #${data.color} !important`);

          $('.tulasoftware-ondemand-iframe')
            .contents()
            .find('.submit-btn')
            .attr(
              'style',
              `border-color: #${data.color} !important; background-color: #${data.color}9E !important`
            );
          $('.tulasoftware-ondemand-iframe')
            .contents()
            .find('.submit-btn')
            .hover(function() {
              $(this).attr(
                'style',
                `background-color: #${data.color} !important`
              );
            });

          $('.tulasoftware-ondemand-chat-iframe')
            .contents()
            .find('.chat-message__submit')
            .attr(
              'style',
              `border-color: #${data.color} !important; background-color: #${data.color}9E !important`
            );
          $('.tulasoftware-ondemand-chat-iframe')
            .contents()
            .find('.chat-message__submit')
            .hover(function() {
              $(this).attr(
                'style',
                `background-color: #${data.color} !important`
              );
            });
          $('.tulasoftware-ondemand-chat-iframe')
            .contents()
            .find('.chat-message__submit.outline')
            .hover(function() {
              $(this).attr(
                'style',
                `background-color: #${data.color}9E !important`
              );
            });

          $('.tulasoftware-purchase-iframe')
            .contents()
            .find('.btn-success')
            .attr(
              'style',
              `border-color: #${data.color} !important; background-color: #${data.color}9E !important`
            );
          $('.tulasoftware-purchase-iframe')
            .contents()
            .find('.btn-success')
            .hover(function() {
              $(this).attr(
                'style',
                `background-color: #${data.color} !important`
              );
            });

          $('.tulasoftware-student-iframe')
            .contents()
            .find('.btn-success')
            .attr(
              'style',
              `border-color: #${data.color} !important; background-color: #${data.color}9E !important`
            );
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.btn-success')
            .hover(function() {
              $(this).attr(
                'style',
                `background-color: #${data.color} !important`
              );
            });
        }

        if (data.type == 'secondary_text_color') {
          $('.button-div').attr('style', `color: #${data.color} !important`);
          $('.nav-option').attr('style', `color: #${data.color} !important`);
        }
        if (data.type == 'navigation_button_text_color_internal_pages') {
          $('.nav-option').attr('style', `color: #${data.color} !important`);
          $('.button-div').attr('style', `color: #${data.color} !important`);
          var defaultColor = $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-nav-pills li.active a')
            .css('color');
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-nav-pills li a')
            .attr('style', `color: #${data.color}9E !important`);
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-nav-pills li.active a')
            .attr('style', `color: #${defaultColor} !important`);
        }

        if (data.type == 'navigation_background_color') {
          $('.overlay-background-footer').attr(
            'style',
            `background-color: #${data.color} !important`
          );
        }
        if (data.type == 'navigation_background_color_internal_pages') {
          $('.overlay-background-footer').attr(
            'style',
            `background-color: #${data.color} !important`
          );
        }
        if (data.type == 'header_background_color_internal_pages') {
          $('.overlay-background-header').attr(
            'style',
            `background-color: #${data.color} !important`
          );
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-nav-pills')
            .attr(
              'style',
              `background-color: #${data.color}30 !important; border-color: #${data.color}9E !important`
            );
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-nav-pills li a')
            .attr('style', `background-color: transparent !important`);
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-nav-pills li')
            .attr(
              'style',
              `background-color: #${data.color}30 !important; border-color: #${data.color}9E !important`
            );
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-nav-pills li a')
            .mouseover(function() {
              $(this).attr(
                'style',
                `background-color: #${data.color}9E !important; border-color: #${data.color} !important`
              );
            });
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-nav-pills li a')
            .mouseout(function() {
              $(this).attr(
                'style',
                `background-color: #${data.color}30 !important; border-color: #${data.color}9E !important`
              );
            });
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-nav-pills li')
            .mouseover(function() {
              $(this).attr(
                'style',
                `background-color: #${data.color}9E !important; border-color: #${data.color} !important`
              );
            });
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-nav-pills li')
            .mouseout(function() {
              $(this).attr(
                'style',
                `background-color: #${data.color}30 !important; border-color: #${data.color}9E !important`
              );
            });
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-nav-pills li.active a')
            .attr('style', `background-color: #${data.color}9E !important`);
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-nav-pills li.active')
            .attr(
              'style',
              `background-color: #${data.color}9E !important; border-color: #${data.color}9E !important`
            );
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-nav-pills li.active a')
            .hover(function() {
              $(this).attr(
                'style',
                `background-color: #${data.color}9E !important`
              );
            });
        }
        if (data.type == 'header_title_text_color_internal_pages') {
          $('.navbar-fixed-top a').attr(
            'style',
            `color: #${data.color} !important`
          );

          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-nav-pills li.active a')
            .attr('style', `color: #${data.color} !important`);
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-nav-pills li.active a')
            .hover(function() {
              $(this).attr('style', `color: #${data.color} !important`);
            });
          $('.tulasoftware-student-iframe')
            .contents()
            .find('.student-nav-pills li a')
            .hover(function() {
              $(this).attr('style', `color: #${data.color} !important`);
            });
        }

        if (data.type == 'background_opacity') {
          $('.overlay').attr('style', `opacity: ${data.opacity} !important`);
        }
        if (data.type == 'background_opacity_header_internal_pages') {
          $('.overlay-background-header').attr(
            'style',
            `opacity: ${data.opacity} !important`
          );
        }
        if (data.type == 'background_opacity_footer_internal_pages') {
          $('.overlay-background-footer').attr(
            'style',
            `opacity: ${data.opacity} !important`
          );
        }
        if (data.type == 'background_opacity_footer') {
          $('.overlay-background-footer').attr(
            'style',
            `opacity: ${data.opacity} !important`
          );
        }
      }
    },
  }
);

window.channel = channel;
export default channel;
