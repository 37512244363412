import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'loading', 'link'];

  displayLoading(event) {
    this.loadingTarget.classList.remove('d-none');
    this.contentTarget.classList.add('d-none');

    let value = event.detail.url;

    this.updateLinks(value);
  }

  displayContent() {
    this.loadingTarget.classList.add('d-none');
    this.contentTarget.classList.remove('d-none');
  }

  updateLinks(item) {
    this.linkTargets.forEach(link => {
      link.classList.remove('selected');
      if (link.href === item) {
        link.classList.add('selected');
      }
    });
  }
}
